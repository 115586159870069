import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import App from "layouts/App";
import store from "stores/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText:false,
      blockAllMedia:false
    }),
  ],
  tracesSampleRate: 1.0, 
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
});

if (module.hot) {
  module.hot.accept();
}

window.store = store;

ReactDOM.render(
	<Provider store={store}>
	  <App />
  </Provider>,
  document.getElementById("root")
);
